<template>
  <v-app>
    <v-container fluid class="white">
      <v-row>
        <!-- Formulario -->
        <div v-if="step == 1">
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              :src="require('@/assets/pat-pos-logo.png')"
              alt="PAT POS Logo"
              class="mx-auto"
              contain
              max-width="146px"
            />
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-center">
            <v-card
              class="bgcards rounded-xl mx-5"
              elevation="2"
              min-height="416px"
            >
              <v-card-title>
                <h3
                  class="text-h5 font-weight-bold"
                  style="word-break: break-word; white-space: normal"
                >
                  Únete a la comunidad PAT
                </h3>

                <p class="text-body-1">
                  Ingresa los datos para crear tu cuenta
                </p>
              </v-card-title>

              <v-card-text>
                <v-form v-model="valid" @submit.prevent="createAccount">
                  <div>
                    <pat-input
                      label="Número celular"
                      placeholder="55 5555 5555"
                      v-model="phoneNumber"
                      :rules="phoneRules"
                      :maxlength="10"
                      :isNumber="true"
                    />
                    <br />
                    <pat-input
                      class="mt-4"
                      label="Crea contraseña"
                      type="password"
                      placeholder="********"
                      v-model="password"
                      :rules="passwordRules"
                    />
                    <div
                      style="
                        margin-top: 5px;
                        font-size: 10px;
                        line-height: 12px;
                      "
                      class="mb-5"
                    >
                      <span
                        :class="{
                          'green--text': passwordValidations.minLength,
                          'black--text': !passwordValidations.minLength,
                        }"
                      >
                        Mínimo 8 caracteres </span
                      ><br />
                      <span
                        :class="{
                          'green--text': passwordValidations.upperCase,
                          'black--text': !passwordValidations.upperCase,
                        }"
                      >
                        Incluye al menos 1 mayúscula </span
                      ><br />
                      <span
                        :class="{
                          'green--text': passwordValidations.lowerCase,
                          'black--text': !passwordValidations.lowerCase,
                        }"
                      >
                        Incluye al menos 1 minúscula </span
                      ><br />
                      <span
                        :class="{
                          'green--text': passwordValidations.specialChar,
                          'black--text': !passwordValidations.specialChar,
                        }"
                      >
                        Contenga al menos 1 carácter especial </span
                      ><br />
                    </div>

                    <pat-input
                      class="mt-4"
                      label="Confirmar contraseña"
                      type="password"
                      placeholder="********"
                      v-model="confirmPassword"
                      :rules="confirmPasswordRules"
                    />
                  </div>
                  <br />
                  <p>
                    Al crear una cuenta PAT aceptas los
                    <a
                      @click.prevent="termsAndconditions"
                      style="cursor: pointer"
                      class="primary-text"
                    >
                      Términos y Condiciones
                    </a>
                    y el
                    <a
                      class="primary-text"
                      @click.prevent="termsAndconditions"
                      style="cursor: pointer"
                    >
                      Aviso de Privacidad
                    </a>
                  </p>

                  <br />
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    block
                    @click="createAccount"
                    rounded
                  >
                    Crear Cuenta
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" class="d-flex justify-center">
            <div>
              <p class="font-weight-bold" style="line-height: 5px">
                <span>¿Ya tienes una cuenta en PAT?</span>
              </p>
              <p
                class="primary--text text-decoration-underline font-weight-bold text-center"
              >
                <span @click="login">Iniciar sesión aqui</span>
              </p>
            </div>
          </v-col>
        </div>

        <!-- Modal de Código de verificación-->
        <verification-code
          v-if="step == 2"
          :phoneNumber="phoneNumber"
          :rol="rol"
          :pathName="rol == 1 ? 'config-account':'home'"
        />

        <!-- Modal de T&C-->
        <div class="text-center">
          <v-dialog
            v-model="dialogTermsAndconditions"
            max-width="400"
            persistent
            content-class="custom-dialog-content"
          >
            <div class="position-relative">
              <v-card class="rounded-xl pa-6" style="background-color: #f4f4fa">
                <!-- Botón de cierre flotante -->
                <v-btn
                  outlined
                  color="primary"
                  class="py-4 border-3"
                  icon
                  style="
                    position: absolute;
                    top: -40px;
                    right: -10px;
                    border-radius: 50%;
                    border-width: 3px;
                    background-color: #fff;
                  "
                  @click="closeDialogTermsAndconditions"
                >
                  <v-icon color="primary pa-0 ma-0">mdi-close</v-icon>
                </v-btn>

                <v-card-title>
                  <h3 class="text-h5 font-weight-bold">
                    T&C y Aviso de privacidad
                  </h3>
                </v-card-title>

                <v-card-text class="overflow-y-auto" style="max-height: 400px">
                  <p class="text-body-1">
                    Es necesario que aceptes para poder crear tu cuenta PAT.
                  </p>
                  <p>
                    <strong>Párrafo 1:</strong> Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nulla facilisi. Vivamus id
                    justo ac erat fermentum feugiat. Integer non ante ut elit
                    volutpat convallis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nulla facilisi. Vivamus id justo ac erat
                    fermentum feugiat. Integer non ante ut elit volutpat
                    convallis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nulla facilisi. Vivamus id justo ac erat
                    fermentum feugiat. Integer non ante ut elit volutpat
                    convallis.
                  </p>
                  <p>
                    <strong>Párrafo 2:</strong> Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nulla facilisi. Vivamus id
                    justo ac erat fermentum feugiat. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Nulla facilisi. Vivamus id
                    justo ac erat fermentum feugiat.
                  </p>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    @click="closeDialogTermsAndconditions"
                    color="grey primary"
                    dark
                    block
                    rounded
                    >Aceptar T&C y Aviso de privacidad</v-btn
                  >
                </v-card-actions>
              </v-card>
            </div>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { register } from '../services/auth';

export default {
  name: "create-account",
  data() {
    return {
      valid: false,
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      step: 1,
      dialogTermsAndconditions: false,
      passwordValidation: "",
      rol: 1,
    };
  },
  computed: {
    // Validaciones
    phoneRules() {
      return [
        (v) => !!v || "Número celular es requerido",
        (v) => /^\d{10}$/.test(v) || "El número celular debe tener 10 dígitos",
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) => v.length >= 8 || "Debe tener al menos 8 caracteres",
        (v) => /[A-Z]/.test(v) || "Debe incluir al menos una mayúscula",
        (v) => /[a-z]/.test(v) || "Debe incluir al menos una minúscula",
        (v) =>
          /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
          "Debe contener al menos un carácter especial",
      ];
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "Confirmación de contraseña es requerida",
        (v) => v === this.password || "Las contraseñas no coinciden",
      ];
    },
    passwordValidations() {
      const hasMinLength = this.password.length >= 8;
      const hasUpperCase = /[A-Z]/.test(this.password);
      const hasLowerCase = /[a-z]/.test(this.password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);

      return {
        minLength: hasMinLength,
        upperCase: hasUpperCase,
        lowerCase: hasLowerCase,
        specialChar: hasSpecialChar,
      };
    },
  },
  methods: {
    defineRole() {
      //Aquí se implementa la lógica para definir el rol, (1 para admin, 2 para empleado)
      this.rol = 1;
    },
    async createAccount() {
      if (this.valid) {
        const response= await register(this.phoneNumber, this.password);
        if (response.success) {
          this.rol = response.tipo;
          this.step = 2;
        } else {
          this.$toast({
            message: response.message,
            color: "error",
            timeout: 2000,
          });
        }
      }
    },
    termsAndconditions() {
      this.dialogTermsAndconditions = true;
      console.log(
        "Enlace de términos y condiciones o aviso de privacidad clickeado"
      );
    },
    closeDialogTermsAndconditions() {
      this.dialogTermsAndconditions = false;
    },
    login() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.custom-dialog-content {
  overflow: visible !important;
}
</style>
